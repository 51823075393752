<template>
  <div>
    <div class="ddic">
      活動摘要
      <div class="divs" id="style-3">
            <a-table
                :columns="columns"
                :row-key="record => record.id"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                :locale="locale"    
                @change="handleTableChange"
                
            >    
                <template slot="sdate" slot-scope="record"> 
                  <span v-text="fdateformat(record.sdate)"></span>
                </template> 
                <template slot="actitle" slot-scope="record"> 
                  <router-link
                    :to="{
                      name:'Actinfo', 
                      params: { id: record.id}
                      }"
                    target="_blank"
                  >{{record.actitle}}</router-link> 
                </template> 
                <template slot="states" slot-scope="record"> 
                  <span v-text="ctypes(record.states)"></span>
                </template>  
                <template slot="action" slot-scope="record"> 
                  <router-link
                    :to="{
                      name:'Joinlist', 
                      params: { id: record.id} 
                      }"
                  >請點選</router-link>
                </template>                                                
            </a-table> 
      </div>
    </div>
  </div>
</template>
<script>
import { actService } from "../../../_services";
import * as moment from "moment/moment";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.findactpage(params);
};

const columns = [
  {
    title: "活動日期",
    dataIndex: "",
    width: "15%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "65%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "states" },
    width: "10%"
  },
  {
    title: " 詳細資料 ",
    dataIndex: "",
    key: "id",
    ellipsis: true,
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "Infomain",
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      data: [],
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },      
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["defaultval"])
  },
  watch: {
    //defaultval : { "name": "連江縣政府", "id": "b3c7a1dc-e6d4-4fac-b1f7-11a2d54e7a25" }
    defaultval() {
      this.reload();
    }
  },
  methods: {

    handleTableChange(pagination, filters, sorter) {
            //console.log(pagination);
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            //console.log(this.pagination);
            this.fetch({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                uid: this.defaultval.id,
                ...filters,
            });
        },     

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "I" ? (tmp = "審核中") : "";
      val == "Y" ? (tmp = "發佈中") : "";
      val == "F" ? (tmp = "結束") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    reload(rs) {
      if (rs == null) {
        this.fetch({
          results: 4,
          page: 1,
          pageSize: 4,
          uid: this.defaultval.id
        });
      } else {
        this.fetch(rs);
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
<style scoped>
.myTable thead {
  background-color: #000000;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style-3 {
  text-align: left;
}
.ddic {
  padding: 20px;
  text-align: left;
  border-radius: 15px;
  border-width: 1px;
  height: 400px;
  margin: 13px auto;
  border-style: dotted;
  border-color: #e0dedc;
  transition: all 0.3s ease;
}
.divs {
  overflow: auto;
  font-size: 14px;
  height: 335px;
}
</style>